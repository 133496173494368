<template>
  <div class="header">
    <div style="position: absolute;left:0">
      <router-link to="/"><el-image lazy class="logo" :src="require('../assets/Home/12.png')" /></router-link>
    </div>
    <div style="display: flex;justify-content: center;">
      <el-menu mode="horizontal"
               router
               :default-active="activeIndex"
               active-text-color="rgb(80,155,90)"
               style="border-bottom:none;height: 60px;">
        <el-menu-item index="/">首页</el-menu-item>
<!--        <el-menu-item>-->
<!--          <router-link to="/app" target="_blank"><div style="height: 100%;">双动APP</div></router-link>-->
<!--        </el-menu-item>-->
        <el-menu-item>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link" style="color: #909399;height: 100%;display:inline-block;">APP下载<i class="el-icon-arrow-down el-icon--right" style="color: #909399;margin-top: -8px"></i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><router-link to="/app" target="_blank"><div style="height: 100%;color: #909399">双动APP</div></router-link></el-dropdown-item>
              <el-dropdown-item><router-link to="/hi" target="_blank"><div style="height: 100%;color: #909399">嗨科普</div></router-link></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <el-menu-item>
          <router-link to="/band" target="_blank"><div style="height: 100%;">欧瑞斯手环</div></router-link>
        </el-menu-item>
        <el-menu-item>
          <router-link to="/game" target="_blank"><div style="height: 100%;">体感互娱</div></router-link>
        </el-menu-item>
        <el-menu-item index="/news">双动资讯</el-menu-item>
        <el-menu-item index="/support">服务与支持</el-menu-item>
        <el-menu-item index="/about">关于双动</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeHeader",
  data(){
    return{

    }
  },
  props: {
    activeIndex: {
      type: String
    },
  },
  methods:{

  }
}

</script>
<style scoped>
.header {
  background: white;
  text-align: center;
  /*line-height: 60px;*/
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 6;
  top: 0;
  min-width: 960px;
  box-shadow: 0 0 3px #404040;
}

.logo{
  margin: 12px 0 12px 50px;
}

@media screen and (max-width: 1060px) {
  .logo{
    margin: 12px 0 12px 10px;
  }
}


.router-link-active {
  text-decoration: none;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: rgb(80, 155, 90);
  outline: 0;
  size: 18px;
}

a {
  text-decoration: none;
}

>>>.el-menu-item * {
  vertical-align: inherit;
}
</style>