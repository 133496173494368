<template>
  <div>
    <el-row :gutter="20" style="background: #3c3c3c;margin: 0;padding-top: 50px">
      <el-col :span="3">
        <h3>双动业务</h3>
        <div>
          <router-link to="/app">双动APP</router-link>
        </div>
        <div>
          <router-link to="/band">欧瑞斯手环</router-link>
        </div>
        <div>
          <router-link to="/game">体感互娱</router-link>
        </div>
      </el-col>
      <el-col :span="3">
        <h3>健康中心</h3>
        <div>
          <router-link to="/course">运动课程</router-link>
        </div>
        <div>
          <router-link to="/recovery">康复训练</router-link>
        </div>
        <div>
          <router-link to="/food">健康饮食</router-link>
        </div>
        <div>
          <router-link to="/consultant">专家顾问</router-link>
        </div>
      </el-col>
      <el-col :span="3">
        <h3>资讯中心</h3>
        <div>
          <router-link to="/news">双动新闻</router-link>
        </div>
<!--        <div>-->
<!--          <router-link to="/activity">展会活动</router-link>-->
<!--        </div>-->
<!--        <div>-->
<!--          <router-link to="#">产品资讯</router-link>-->
<!--        </div>-->
<!--        <div>-->
<!--          <router-link to="/game">互娱</router-link>-->
<!--        </div>-->
      </el-col>
      <el-col :span="3">
        <h3>服务与支持</h3>
        <div>
          <router-link to="/support">服务与支持</router-link>
        </div>
      </el-col>
      <el-col :span="3">
        <h3>关于双动</h3>
        <div>
          <router-link to="/">双动官网</router-link>
        </div>
        <div>
          <router-link to="/about">公司简介</router-link>
        </div>
        <div>
          <router-link to="/history">发展历程</router-link>
        </div>
        <div>
          <router-link to="/contact">联系我们</router-link>
        </div>
      </el-col>
      <el-col :span="8" :offset="1">
        <div>
          <el-image :src="require('../assets/Home/11.jpg')" lazy style="width: 10vw;height: 10vw;min-height: 96px;min-width: 96px;"/>
          <p style="text-align: center;margin-top: 10px">双动公众号</p>
        </div>
      </el-col>
    </el-row>
    <el-row style="background: #3c3c3c;color: #fff;text-align: left;padding-top:80px;padding-bottom: 50px">
      <el-col :span="12" :offset="1">
        广州双动科技有限公司 备案/许可证号：<a href="https://beian.miit.gov.cn">粤ICP备17086371号</a>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.el-col div {
  margin: 30px 0;
  color: #fff;
}

h3 {
  color: #fff;
}

a:link, a:visited {
  color: #ffffff;
}

a:hover{
  color: rgb(80,155,90);
}

a {
  text-decoration: none;
}

.sdiv {
  transform: translateY(50px);
  transition: transform 0.75s, opacity 0.75s;
  opacity: 0;
}

.sdiv.on {
  transform: none;
  opacity: 1;
}
</style>