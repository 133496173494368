<template>
  <div class="all">
    <HomeHeader activeIndex="/"></HomeHeader>
    <div class="main">
      <swiper :options="swiperOption">
        <swiper-slide v-for="item in swiperSlide" :key="item.index">
          <a :href="item.url" target="_blank">
            <img :src="item.imgUrl" class="swiperImg"/>
          </a>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination"></div>
      </swiper>

      <h3 style="margin-top: 20px">双动推荐</h3>
      <p style="border: 3px solid #3e3e3e;border-radius:5px;width: 40px;margin: 10px auto"></p>
      <h3 style="margin-bottom: 20px">RECOMMEND</h3>

      <!--                上-->
      <div id="top" style="margin: 0 5vw;display: flex;align-items: center;justify-content: center;">
        <div class="left" style="float: left;width: 59vw;min-width:566px;margin-right:1vw;">
          <a href="http://www.toodo.com.cn/#/news2?address=https%3A%2F%2Fact-web.toodo.com.cn%2Fofficial-website%2Fnews%2F220201113%2F220201113.html">
            <div :style="background" class="top"
                 style="border: 1px solid #e5e5e5;border-radius: 4px;position: relative;background-size:100% 100%;background-repeat:no-repeat;height:25vw;min-height: 240px">
              <el-image class="img" :src="require('../assets/Home/推荐1.jpg')" lazy/>
              <div
                  style="color:#eaeaea;background: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,50));width: 100%;height: 90px;position: absolute;bottom: 0;">
                <p class="smallTitle">新闻</p>
                <p class="content">
                  “爆款星主播”节目指定产品欧瑞斯</p>
              </div>
            </div>
          </a>
          <div style="height: 1vw;min-height:10px"></div>
          <div class="down" style="">
            <router-link to="/product1">
              <div :style="background2" class="downLeft"
                   style="border: 1px solid #e5e5e5;border-radius: 4px;position: relative;background-size:100% 100%;background-repeat:no-repeat;float: left;
                   width: 29vw;height:25vw;min-width: 278px;min-height: 240px">
                <el-image class="img2" :src="require('../assets/Home/推荐2.jpg')" lazy/>
                <div
                    style="color:#eaeaea;background: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,50));width: 100%;height: 90px;position: absolute;bottom: 0;">
                  <p class="smallTitle">欧瑞斯手环</p>
                  <p class="content">欧瑞斯运动手表“星火”新品上市</p>
                </div>
              </div>
            </router-link>
            <router-link to="/game">
              <div :style="background3" class="downRight" style="border: 1px solid #e5e5e5;border-radius: 4px;position: relative;background-size:100% 100%;background-repeat:no-repeat;width: 29vw;
                        height:25vw;min-width: 278px;min-height: 240px;float: right;">
                <el-image class="img3" :src="require('../assets/Home/推荐3.jpg')" lazy/>
                <div style="color:#eaeaea;background: linear-gradient(rgba(255,255,255,0),
                rgba(0,0,0,50));width: 100%;height: 90px;position: absolute;bottom: 0;">
                  <p class="smallTitle">互娱体感</p>
                  <p class="content">体感热血网球，超过瘾体感控制体验</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <a href="http://www.toodo.com.cn/#/news2?address=https%3A%2F%2Fact-web.toodo.com.cn%2Fofficial-website%2Fnews%2F520201117%2F520201117.html"
           target="_blank">
          <div class="right" :style="background4" style="border: 1px solid #e5e5e5;border-radius: 4px;position: relative;background-size:100% 100%;background-repeat:no-repeat;width: 28vw;float: left;
                height: 51vw;min-width: 269px;min-height: 490px">
            <el-image class="img4" :src="require('../assets/Home/推荐4.jpg')" lazy/>
            <div
                style="color:#eaeaea;background: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,50));width: 100%;height: 180px;position: absolute;bottom: 0;">
              <p class="smallTitle">APP</p>
              <p class="content">双动健康管理平台正式上线</p>
            </div>
          </div>
        </a>
      </div>

      <!--                中-->
      <div style="width: 90%;margin: 50px 5% 0 5%">
        <div style="border: 1px solid #e5e5e5;border-radius: 4px;float:left;width: 49.5%;position: relative"
             @mouseover="videoPlayback()" @mouseout="videoStopped()">
          <div id="text" style="">
            <h2>欧瑞斯</h2>
            <h3 style="margin: 5px 0;">健康触手可及</h3>
            <p class="videoText">欧瑞斯推出“炫动”运动手环、“星火”智能手表、“运动小秘”智能设备，可在多种运动模式下感应心率、血压、体温、步弧、
              步数等每一组运动健康的多维度数据， 是您触手可及的健康管理专家。</p>
          </div>
          <img id="img" src="../assets/Home/欧瑞斯视频封面.jpg"/>
          <video preload="none" id="video" loop muted width="100%">
            <source src="https://act-web.toodo.com.cn/official-website/video/home1.mp4" type="video/mp4">
          </video>
        </div>

        <div style="border: 1px solid #e5e5e5;border-radius: 4px;float: right;width: 49.5%;position: relative"
             @mouseover="videoPlayback2()" @mouseout="videoStopped2()">
          <div id="text2" style="">
            <h2>双动互娱</h2>
            <h3 style="margin: 10px 0;">足不出户，在家运动</h3>
            <p class="videoText">互娱包含的体感运动、运动健康视频让用户可以通过手机、PC、电视等多媒体平台，进行个人或多人一起的体感互动。
              无论什么场景都可以通过轻松的方式，带给用户健康、愉悦的品牌体验。</p>
          </div>
          <img id="img2" src="../assets/Home/互娱视频封面.jpg"/>
          <video preload="none" id="video2" loop muted width="100%">
            <source src="https://act-web.toodo.com.cn/official-website/video/home2.mp4" type="video/mp4">
          </video>
        </div>
        <div class="clear"></div>
      </div>

      <div style="position: absolute;z-index: 3;width: 25vw;min-width:240px;height:41vw;min-height:394px;
        background: rgba(0,0,0,0.3);margin-left: 100px;color: #fff;margin-top: 50px">
        <div style="position: absolute;top: 50%;left:50%;transform: translate(-50%,-50%)">
          <el-image :src="require('../assets/Home/9.png')"
                    style="width: 6vw;height: 6vw;min-width: 60px;min-height: 60px;"/>
          <p style="margin: 10px;">双动APP</p>
          <p style="">您的健康生活管家</p>
          <el-image :src="require('../assets/Home/10.jpg')"
                    style="margin-top: 60px;height: 12vw;width: 12vw;min-width: 116px;min-height: 116px"/>
          <p style="margin-top: 10px">扫码下载</p>
        </div>
      </div>

      <swiper :options="swiperOption2" style="margin-top: 50px">
        <swiper-slide v-for="item in swiperSlide2" :key="item.index2">
          <img class="swiperImg" :src="item.imgUrl"/>
        </swiper-slide>
      </swiper>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import HomeHeader from "../components/HomeHeader";

export default {
  name: "Home",
  data() {
    return {
      opacityStyle: {opacity: 0},
      visible:false,
      swiperSlide: [
        {
          url: 'http://www.toodo.com.cn/#/news2?address=https%3A%2F%2Fact-web.toodo.com.cn%2Fofficial-website%2Fnews%2F220201113%2F220201113.html',
          imgUrl: require('../assets/Home/爆款星主播2.jpg')
        },
        {
          url: "https://www.toodo.com.cn/#/product1",
          imgUrl: require('../assets/Home/星火海报1920-800.jpg')
        },
        {
          url: "http://www.toodo.com.cn/#/news2?address=https%3A%2F%2Fact-web.toodo.com.cn%2Fofficial-website%2Fnews%2F120201113%2F120201113.html",
          imgUrl: require('../assets/Home/官网首页轮播-课程包.jpg')
        },
        {
          url: "http://www.toodo.com.cn/#/news2?address=https%3A%2F%2Fact-web.toodo.com.cn%2Fofficial-website%2Fnews%2F620201231%2F620201231.html",
          imgUrl: require('../assets/Home/双动科技荣获中国民族卫生协会共建基地.jpg')
        }],
      swiperSlide2: [
        {imgUrl: require('../assets/Home/专业.jpg')},
        {imgUrl: require('../assets/Home/健康.jpg')},
        {imgUrl: require('../assets/Home/轻松.jpg')},
      ],
      swiperOption: {
        loop: true,
        keyboard: {enabled: true}, //键盘
        speed: 1500,
        centeredSlides: true,
        autoplay: {
          autoplay: true,
          delay: 2000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }},
      swiperOption2: {
        loop: true,
        speed: 1500,
        centeredSlides: true,
        autoplay: {
          autoplay: true,
          delay: 2000,
          disableOnInteraction: false
        }},
      background: {
        background: 'url(' + require('../assets/Home/推荐1-1.jpg') + ')',
        backgroundSize: '100% 100%',
      },
      background2: {
        background: 'url(' + require('../assets/Home/推荐2-1.jpg') + ')',
        backgroundSize: '100% 100%',
      },
      background3: {
        background: 'url(' + require('../assets/Home/推荐3-1动图.gif') + ')',
        backgroundSize: '100% 100%',
      },
      background4: {
        background: 'url(' + require('../assets/Home/推荐4-1.gif') + ')',
        backgroundSize: '100% 100%',
      },
      background5: {
        background: 'url(' + require('../assets/Home/6.jpg') + ')'
      },
      background6: {
        background: 'url(' + require('../assets/Home/7.jpg') + ')'
      },
      background7: {
        background: 'url(' + require('../assets/Home/8.jpg') + ')'
      },
    }
  },

  props: {
    activeIndex: {
      type: String
    },
  },

  components: {
    Footer, HomeHeader
  },

  methods: {
    videoPlayback() {
      let text = document.getElementById('text');
      let video = document.getElementById('video');
      let img = document.getElementById('img');
      text.style.opacity = "0";
      img.style.opacity = "0";
      video.setAttribute("autoplay", "autoplay")
      video.play();
    },

    videoStopped() {
      let img = document.getElementById('img');
      let video = document.getElementById('video');
      let text = document.getElementById('text');
      text.style.opacity = "1";
      img.style.opacity = "1";
      video.pause();

    },

    videoPlayback2() {
      let text2 = document.getElementById('text2');
      let video2 = document.getElementById('video2');
      let img2 = document.getElementById('img2');
      text2.style.opacity = "0";
      img2.style.opacity = "0";
      video2.setAttribute("autoplay", "autoplay")
      video2.play();
    },

    videoStopped2() {
      let img2 = document.getElementById('img2');
      let text2 = document.getElementById('text2');
      let video2 = document.getElementById('video2');
      text2.style.opacity = "1";
      img2.style.opacity = "1";
      video2.pause();
    },
  },
}
</script>

<style scoped>
.swiperImg {
  height: 41vw;
  width: 100vw;
  min-height: 394px;
  min-width: 960px;
  display: block
}

.videoText {
  text-indent: 2em;
  text-align: left;
  margin: 0 10%;
}

.content {
  position: absolute;
  bottom: 0;
  left: 10px;
  letter-spacing: 2px;
  margin: 5px 0
}

.smallTitle {
  position: absolute;
  bottom: 30px;
  left: 10px;
  letter-spacing: 2px;
}

@media screen and (max-width: 576px) {
  .smallTitle {
    font-size: 10px;
  }

  .videoText {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .smallTitle {
    font-size: 12px;
  }

  .videoText {
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .smallTitle {
    font-size: 12px;
  }

  .videoText {
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .smallTitle {
    font-size: 14px;
  }

  .videoText {
    font-size: 14px;
  }
}

.clear {
  clear: both;
}

.all {
  background: white;
  margin: 0;
  padding: 0;
  min-width: 960px;
}

.main {
  text-align: center;
  margin: 60px 0 0 0;
  padding: 0;
  overflow: hidden;
}

#text, #text2 {
  position: absolute;
  z-index: 5;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  transition-duration: 0.8s;
}

.img, .img2, .img3, .img4 {
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

#img, #img2 {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
  transition-duration: 1s;
}

#img:hover, #img2:hover {
  opacity: 0;
}

.right:hover .img4, .downLeft:hover .img2, .downRight:hover .img3, .top:hover .img {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
</style>