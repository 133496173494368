<template>
    <div id="app">
        <el-backtop/>
        <router-view/>
    </div>
</template>

<script>
import $ from "jquery";

export default {
  mounted() {
    this.init('.divtest');
  },

  methods: {
    /*初始化*/
    init(_el) {
      this.start(_el);
      let own = this;
      $(window).on('scroll', function () {
        own.start(_el)
      });
    },
    /*开始*/
    start(_el) {
      $(_el).each(function () {
        let _self = $(this);
        /*滚动高度*/
        let isScrollTop = $(window).scrollTop();
        /*滚动视度*/
        let isWindowHeiget = $(window).height() * 0.8;
        /**/
        let _class = $(this).data('animation');
        if (isScrollTop + isWindowHeiget > $(this).offset().top) {
          _self.addClass(_class);
        }
      });
    },
  }
}
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        /*font-size: 14px;*/
    }

    body {
        margin: 0;
        padding: 0;
    }

    .sdiv {
      transform: translateY(50px);
      transition: transform 0.75s, opacity 0.75s;
      opacity: 0;
    }

    .sdiv.on {
      transform: none;
      opacity: 1;
    }

    @media screen and (max-width: 576px) {
      #app{
        font-size: 12px;
      }
    }

    @media screen and (max-width: 1200px) {
      #app{
        font-size: 14px;
      }
    }

    @media screen and (min-width: 1200px) {
      #app{
        font-size: 16px;
      }
    }
</style>
