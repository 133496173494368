import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import AMap from 'vue-amap'
import axios from 'axios'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import jquery from "jquery"
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

Vue.use(Viewer)
Vue.use(Vuetify)
Vue.use(VueAwesomeSwiper)
Vue.prototype.$axios = axios;
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(AMap);
Vue.prototype.$=jquery;

const opts = {}
export default new Vuetify(opts)

axios.defaults.baseURL = 'https://tddev.toodo.com.cn/td-web-srv/api/';

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
})

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德的key
  key: 'b8a65e06e7542569ffb5fd2b58ac337e',
});

new Vue({
  router,
  axios,
  jquery,
  render: h => h(App)
}).$mount('#app')
