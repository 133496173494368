import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {   //Home
        path: '/',
        name: 'Home',
        meta:{
            title:'双动科技',
        },
        component: Home
    },

    //APP站点
    {   //APP首页
        path: '/app',
        name: 'App',
        meta:{
            title:'双动APP'
        },
        favicon:'@/public/favicon.ico',
        component: () => import('../views/App/App.vue')
    },
    {   //Hi
        path: '/hi',
        name: 'Hi',
        meta:{
            title:'嗨科普'
        },
        favicon:'@/public/favicon.ico',
        component: () => import('../views/App/Hi.vue')
    },
    {   //运动课程
        path: '/course',
        name: 'Course',
        meta:{
            title:'运动课程'
        },
        component: () => import('../views/App/Course.vue')
    },
    {   //运动课程详情
        path: '/course2',
        name: 'Course2',
        meta:{
            title:'运动课程详情'
        },
        component: () => import('../views/App/Course2.vue')
    },
    {   //训练康复
        path: '/recovery',
        name: 'Recovery',
        meta:{
            title:'训练康复'
        },
        component: () => import('../views/App/Recovery.vue')
    },
    {   //健康饮食
        path: '/food',
        name: 'Food',
        meta:{
            title:'健康饮食'
        },
        component: () => import('../views/App/Food.vue')
    },
    {   //健康顾问
        path: '/consultant',
        name: 'Consultant',
        meta:{
            title:'健康顾问'
        },
        component: () => import('../views/App/Consultant.vue')
    },
    {
        path: '/expert',
        name: 'Expert',
        meta:{
            title:'健康顾问详情'
        },
        component: () => import('../views/App/Expert.vue')
    },

    //双动手环站点
    {   //手环首页
        path: '/band',
        name: 'Band',
        meta:{
            title:'欧瑞斯'
        },
        component: () => import('../views/Band/Band.vue')
    },
    {   //产品
        path: '/product',
        name: 'Product',
        meta:{
            title:'产品'
        },
        component: () => import('../views/Band/Product.vue')
    },
    {   //炫动系列
        path: '/product1',
        name: 'Product1',
        meta:{
            title:'产品详情'
        },
        component: () => import('../views/Band/Product1.vue')
    },
    {   //儿童系列
        path: '/product2',
        name: 'Product2',
        meta:{
            title:'产品详情'
        },
        component: () => import('../views/Band/Product2.vue')
    },
    // {   //老人系列
    //     path: '/old',
    //     name: 'Old',
    //     component: () => import('../views/Band/Old.vue')
    // },

    //双动站点
    {   //互娱
        path: '/game',
        name: 'Game',
        meta:{
            title:'互娱体感'
        },
        component: () => import('../views/Game/Game.vue')
    },
    // {   //互娱体感
    //     path: '/game2',
    //     name: 'Game2',
    //     meta:{
    //         title:'详情'
    //     },
    //     component: () => import('../views/Game/Game2.vue')
    // },
    // {   //互娱体感
    //     path: '/game3',
    //     name: 'Game3',
    //     meta:{
    //         title:'详情'
    //     },
    //     component: () => import('../views/Game/Game3.vue')
    // },
    // {   //互娱体感
    //     path: '/game4',
    //     name: 'Game4',
    //     meta:{
    //         title:'详情'
    //     },
    //     component: () => import('../views/Game/Game4.vue')
    // },

    //双动资讯站点
    {   //双动新闻
        path: '/news',
        name: 'News',
        meta:{
            title:'双动新闻'
        },
        component: () => import('../views/News/News.vue'),

    },
    // {   //展会活动
    //     path: '/activity',
    //     name: 'Activity',
    //     meta:{
    //         title:'展会活动'
    //     },
    //     component: () => import('../views/News/Activity.vue')
    // },
    {   //双动新闻内容页面
        path: '/news2',
        name: 'News2',
        meta:{
            title:'新闻详情'
        },
        component: () => import('../views/News/News2.vue'),
    },

    //服务与支持站点
    {   //服务与支持
        path: '/support',
        name: 'Support',
        meta:{
            title:'服务与支持'
        },
        component: () => import('../views/Support/Support.vue')
    },

    //关于双动站点
    {   //公司简介
        path: '/about',
        name: 'About',
        meta:{
            title:'公司简介'
        },
        component: () => import('../views/About/About.vue')
    },
    {   //联系我们
        path: '/contact',
        name: 'Contact',
        meta:{
            title:'联系我们'
        },
        component: () => import('../views/About/Contact.vue')
    },
    {   //发展历程
        path: '/history',
        name: 'History',
        meta:{
            title:'发展历程'
        },
        component: () => import('../views/About/History.vue')
    },
    {   //合作伙伴
        path: '/partner',
        name: 'Partner',
        meta:{
            title:'合作伙伴'
        },
        component: () => import('../views/About/Partner.vue')
    },
    {   //双动业务
        path: '/business',
        name: 'Business',
        meta:{
            title:'双动业务'
        },
        component: () => import('../views/About/Business.vue')
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
})

export default router
